import React from 'react';
import { Box, CircularProgress } from '@mui/material';


/**
 * 
 * @prop {string} customHeight - set the height of the spinner, otherwise it will default to 100vh
 * @returns a loading spinner that will take up the entire screen or a custom height
 */
export default function LoadingSpinner(props) {

    // if a props.height is passed, use it, otherwise set it to 100vh
    const customHeight = props.customHeight ? props.customHeight : '100vh';


    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: customHeight
            }}
        >
            <CircularProgress />
        </Box>
    );
}
