import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "./shared/theming/styles.css";
import { LicenseManager } from 'ag-grid-enterprise';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AGKey from './config/AGKey.json';
import './shared/fonts/inter.css';
import RiTheme from './shared/theming/RiTheme';
import './shared/theming/ag-theme-ri-leaderboard.css';
import './shared/theming/ag-theme-ri.css';
import './shared/theming/ag-theme-ri-notespage.css';
import './shared/theming/ag-theme-ri-penaltytool.css';
import "./shared/theming/ag-theme-ri-scanner.css";
import LoadingSpinner from './shared/SharedComponents/LoadingSpinner';

LicenseManager.setLicenseKey(AGKey.key);

const App = lazy(() => import('./App'));
const Home = lazy(() => import('./routes/Home'));
const DriverList = lazy(() => import('./routes/Stats/Drivers/DriverList/DriverList'));
const DriverPage = lazy(() => import('./routes/Stats/Drivers/DriverPage/DriverPage'));
const SearchPage = lazy(() => import('./routes/SearchPage/SearchPage'));
const EventPage = lazy(() => import('./routes/Stats/Events/EventsPage/EventPage'));
const EventsList = lazy(() => import('./routes/Stats/Events/EventsList/EventsList'));
const TeamsList = lazy(() => import('./routes/Stats/Teams/TeamList/TeamsList'));
const TeamPage = lazy(() => import('./routes/Stats/Teams/TeamPage/TeamPage'));
const SeasonsList = lazy(() => import('./routes/Stats/Seasons/SeasonList/SeasonsList'));
const SeasonsPage = lazy(() => import('./routes/Stats/Seasons/SeasonPage/SeasonsPage'));
const TracksList = lazy(() => import('./routes/Stats/Tracks/TrackList/TracksList'));
const TracksPage = lazy(() => import('./routes/Stats/Tracks/TrackPage/TracksPage'));
const PrivacyPolicy = lazy(() => import('./routes/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./routes/TermsOfService'));
const LiveLeaderboard = lazy(() => import('./routes/Live/LiveLeaderboard/LiveLeaderboard'));
const FilesFeed = lazy(() => import('./routes/FilesFeed/FilesFeed'));
const Scanner = lazy(() => import('./routes/Live/Scanner/Scanner'));
const NotesPage = lazy(() => import('./routes/Live/Notes/NotesPage'));
const InsightsPage = lazy(() => import('./routes/Stats/Insights/InsightsPage'));
const AdminPage = lazy(() => import('./routes/Tools/AdminPage/AdminPage'));
const PenaltyEntryTool = lazy(() => import('./routes/Tools/PenaltyEntryTool/PenaltyEntryTool'));
const ProDataTool = lazy(() => import('./routes/Tools/ProDataTool/ProDataTool'));
const PlayoffsTool = lazy(() => import('./routes/Tools/PlayoffsTool/PlayoffsTool'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={RiTheme}>
        <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route element={<App />}>
                        <Route path="/" element={<Home />} />
                        <Route path="drivers" element={<DriverList />} />
                        <Route path="drivers/:series" element={<DriverList />} />
                        <Route path="drivers/:series/:driverId" element={<DriverPage />} />
                        <Route path="search" element={<SearchPage />} />
                        <Route path="event" element={<EventPage />} />
                        <Route path="event/:eventId" element={<EventPage />} />
                        <Route path="events" element={<EventsList />} />
                        <Route path="events/:series" element={<EventsList />} />
                        <Route path="teams" element={<TeamsList />} />
                        <Route path="teams/:series" element={<TeamsList />} />
                        <Route path="teams/:series/:teamOwnerId" element={<TeamPage />} />
                        <Route path="seasons" element={<SeasonsList />} />
                        <Route path="seasons/:series" element={<SeasonsList />} />
                        <Route path="seasons/:series/:year" element={<SeasonsPage />} />
                        <Route path="tracks/:series" element={<TracksList />} />
                        <Route path="tracks/:series/:trackGroup" element={<TracksPage />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<TermsOfService />} />
                        <Route path="/live/leaderboard/:series" element={<LiveLeaderboard />} />
                        <Route path="/files" element={<FilesFeed />} />
                        <Route path="/live/scanner/:series" element={<Scanner />} />
                        <Route path="/notes/:series" element={<NotesPage />} />
                        <Route path="/insights" element={<InsightsPage />} />
                        <Route path="/admin" element={<AdminPage />} />
                        <Route path="/tools/penaltyentry/:series" element={<PenaltyEntryTool />} />
                        <Route path="/tools/prodata/:series" element={<ProDataTool />} />
                        <Route path='/tools/playoffs/:series' element={<PlayoffsTool />} />
                        <Route
                            path="*"
                            element={
                                <main style={{ padding: '1rem' }}>
                                    <h1>Page Not Found</h1>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
        <CssBaseline />
    </ThemeProvider>
);

